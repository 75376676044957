import React from "react";

const InputFormText = (props) => {
  return (
    <div className={props.inputFormText_container}>
      <input
        type="text"
        className={props.text_className}
        placeholder={props.placeholderInputText}
        value={props.valueForm}
        name={props.name}
        onChange={props.onChangeValue}
        required={props.requiredOrNot}
      />
    </div>
  );
};
const InputFormTextArea = (props) => {
  return (
    <div className={props.inputFormTextArea_container}>
      <textarea
        type="text"
        className={props.text_className}
        placeholder={props.placeholderInputText}
        value={props.valueForm}
        name={props.name}
        onChange={props.onChangeValue}
        required
      ></textarea>
    </div>
  );
};
const InputFormPassword = (props) => {
  return (
    <div className={props.inputPassword_container}>
      <input
        type="password"
        className={props.password_className}
        placeholder={props.placeholderInputPassword}
        value={props.valueForm}
        name={props.name}
        onChange={props.onChangeValue}
        required
      />
    </div>
  );
};
const InputFormEmail = (props) => {
  return (
    <div className={props.inputFormEmail_container}>
      <input
        type="email"
        className={props.email_className}
        placeholder={props.placeholderInputEmail}
        value={props.valueForm}
        name={props.name}
        onChange={props.onChangeValue}
        required
      />
    </div>
  );
};
const InputFormCode = (props) => {
  return (
    <div>
      <input
        type="number"
        className={props.code_className}
        placeholder={props.placeholderInputCode}
        value={props.valueForm}
        name={props.name}
        onChange={props.onChangeValue}
      />
    </div>
  );
};
const InputFormAdresse = (props) => {
  return (
    <div>
      <input
        type="text"
        className={props.adresse_className}
        placeholder={props.placeholderInputAdresse}
        value={props.valueForm}
        name={props.name}
        onChange={props.onChangeValue}
      />
    </div>
  );
};
const InputFormNumber = (props) => {
  return (
    <div>
      <input
        type="number"
        className={props.number_className}
        placeholder={props.placeholderInputNumber}
        value={props.valueForm}
        name={props.name}
        onChange={props.onChangeValue}
        disabled={props.disabled}
        min={1}
      />
    </div>
  );
};

export {
  InputFormText,
  InputFormTextArea,
  InputFormPassword,
  InputFormCode,
  InputFormEmail,
  InputFormAdresse,
  InputFormNumber,
};
