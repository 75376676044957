import React from 'react'

export const language = {
de: 'DE', 
es: 'ES',
}

export const UserLanguagePrefer = React.createContext(
    language.de,
)
