import { createContext, useContext, useEffect, useState } from "react"

const myContext = createContext()

export const useMyContext = () => {
    const context = useContext(myContext)
    return context
}

export const MyContextProvider = ({ children }) => {
const [ user, setUser ] = useState('')
const [ language, setLanguage ] = useState('')

useEffect(()=>{
    setUser({name: 'yoriel'})
    setLanguage({ language: 'Espannol' })
}, [])
    

    return (
        <myContext.Provider value={{ user, language }} >
            { children }
        </myContext.Provider>
    )
}