import React, { Component, lazy, Suspense } from "react";
import Header from "./componentes/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { autenticacion } from "./helpers/firebase";
import {UserLanguagePrefer, language} from "./componentes/myContext";
import AGBKundeninfo from "./paginas/agbKundenInfo";
import Datenschutzerklaerung from "./paginas/datenschutzerklaerung";
import Wiederrufserklaerung from "./paginas/wiederrufserklaerung";
import Wiederrufspolitik from "./paginas/wiederrufspolitik";
import Zahlungsarten from "./paginas/zahlungsarten";
import Impressum from "./paginas/impressum";
import AboutUs from "./paginas/about_us";
import { MyContextProvider } from "./context/context";
// import Error from "./paginas/error";

const CreateAccount = lazy(() => import("./paginas/create_account"));
const UserAccount = lazy(() => import("./paginas/user_account"));
const ArtikelsDetails = lazy(() => import("./paginas/artikels_details"));
const ArtikelAdd = lazy(() => import("./paginas/artikel_add"));
const Home = lazy(() => import("./paginas/home"));
const FooterSite = lazy(() => import("./paginas/footer_site"));
const Kaufwagen = lazy(() => import("./paginas/kaufwagen"));
const Gratitude = lazy(() => import("./paginas/gratitude_site"));
const MyOrders = lazy(() => import("./paginas/my_orders"));
const UserPasswordReset = lazy(() => import("./paginas/user_password_reset"));
const UserNextStep = lazy(() => import("./paginas/user_next_step"));
const MadeOrders = lazy(() => import("./paginas/made_orders"));



class App extends Component {
  constructor() {
    super();

    this.state = {
      userEmail: "",
      userPassword: "",
      userID: "",
      language: language.de
    };

    this.onToggleLanguage = ()=>{
      this.setState(state => ({
        language: 
        state.language === language.de 
        ? (language.es, localStorage.setItem('sp', 'ES'))
        : (language.de, localStorage.setItem('sp', 'DE')),
      }))
    window.location.reload()
    }
  }
  componentDidMount() {
    this.setState({
      language: localStorage.getItem('sp')
    })
    autenticacion.onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          userEmail: user.email,
          userID: user.uid,
        });
      }
    });
  }

  render() {
    const language = this.state.language
    return (
      <div>
        <Router>
          <Suspense fallback={<span>Loading...</span>}>
            <MyContextProvider>
             <UserLanguagePrefer.Provider value={ language }>
           <h1>Lo sentimos, la tienda está siendo actualizada. Dentro de poco estará disponible</h1>
             {/* <Header state={this.state} />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/create_account" component={CreateAccount} />
                <Route exact path="/user_account" component={UserAccount} />
                <Route exact path="/artikel_add" component={ArtikelAdd} />
                <Route exact path="/kaufwagen" component={Kaufwagen} />
                <Route exact path="/gratitude_site" component={Gratitude} />
                <Route exact path="/my_orders" component={MyOrders} />
                <Route exact path="/user_next_step" component={UserNextStep} />
                <Route exact path="/made_orders" component={MadeOrders} />
                <Route exact path="/agbKundenInfo" component={AGBKundeninfo} />
                <Route exact path="/datenschutzerklaerung" component={Datenschutzerklaerung} />
                <Route exact path="/wiederrufserklaerung" component={Wiederrufserklaerung} />
                <Route exact path="/wiederrufspolitik" component={Wiederrufspolitik} />
                <Route exact path="/zahlungsarten" component={Zahlungsarten} />
                <Route exact path="/impressum" component={Impressum} />
                <Route exact path="/about_us" component={AboutUs} />
                <Route
                  exact
                  path="/user_password_reset"
                  component={UserPasswordReset}
                  />
                <Route
                  exact
                  path="/artikels_details/:id"
                  component={ArtikelsDetails}
                  />
              </Switch>
              <FooterSite state={this.state} onToggleLanguage={this.onToggleLanguage} /> */}
            </UserLanguagePrefer.Provider>
           </MyContextProvider>
          </Suspense>
        </Router>
      </div>
    );
  }
}

export {UserLanguagePrefer, App};
