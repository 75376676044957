import React, {useState} from 'react'

export const StoppUhr = (props) => {
    let std = props.stunden
    let min = props.minuten
    let sek = props.sekunden
    return (
        <div>
            <h1>
                Stell dein Stopp Uhr ein
            </h1>
            <div>
                {
                    std >= 10
                        ? <></>
                        : <> 0</>
                }
                {std}: {
                    min >= 10
                        ? <></>
                        : <> 0</>
                }
                {min}: {
                    sek >= 10
                        ? <></>
                        : <> 0</>
                }
                {sek}
            </div>
        </div>
    )
}

export const EingabeZeit = () => {
    const [stunden, setStunden] = useState(1)
    const [minuten, setMinuten] = useState(3)
    const [sekunden, setSekunden] = useState(5)

    const handleStunden = (e) => {
        let std = e.target.value
        if(std > 23) {
            setStunden(23)
        }else {
            setStunden(stunden)
        }
      }

    const handleSekunden = (e) => {
        let sek = e.target.value
        if(sek > 59){
            setSekunden(59)
        }else {
            setSekunden(sek)
        }
    }
    
    const handleMinuten = (e) => {
        let min = e.target.value
        if(min > 59){
            setMinuten(59)
        }else {
            setMinuten(min)
        }
    }

    let timer;
    function handleStart() {
        timer = setInterval(countDownSekunden, 1000)
    }
    
    function handleStopp (){
            clearInterval(timer)
    }
    
    
    const handleReset = () => {
       clearInterval(timer)
       setMinuten(3)
       setSekunden(5)
       setStunden(1)
    }

    let sek = sekunden
    let min = minuten
    let std = stunden

    const countDownSekunden = () => {
        sek--
        setSekunden(sek)

        switch (true) {
            case std === 0 && min === 0 && sek === 0:
                clearInterval(timer)
                alert('Zeit abgelaufen')
                 setStunden(0)
                setMinuten(0)
                setSekunden(0)
                break
            case min === 0 && std !== 0:
                std--
                min = 59
                setStunden(std)
                setMinuten(min)
                break
            case sek === 0 && min !== 0:
                min--
                sek = 60
                setMinuten(min)
        }
    }

    return (
        <div>
            <StoppUhr 
                stunden={stunden} 
                minuten={minuten} 
                sekunden={sekunden}/>
            
            <input
                type='number'
                min={0}
                max={59}
                onChange={handleStunden}
                name='stunden'
                value={stunden}/>
            <input
                type='number'
                min={0}
                max={59}
                onChange={handleMinuten}
                name='minuten'
                value={minuten}/>
            <input
                type='number'
                onChange={handleSekunden}
                name='sekunden'
                value={sekunden}
                min={0}
                max={59}/>

            <button onClick={handleStart}>Start</button>
            <button onClick={handleStopp}>Stopp</button>
            <button onClick={handleReset}>Reset</button> 
        </div>
    )
}

function AboutUs() {
    return (
        <div>
            <h1>Sobre nosotros</h1>
            <EingabeZeit />
        </div>
    )
}

export default AboutUs;