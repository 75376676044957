import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/messaging";

import { toast } from "react-toastify";
toast.configure();

firebase.initializeApp({
  apiKey: "AIzaSyDRlLIK_pF-pmc1NSej5NuX5RAeWqC0ugo",
  authDomain: "birkesshop-54edb.firebaseapp.com",
  databaseURL: "https://birkesshop-54edb.firebaseio.com",
  projectId: "birkesshop-54edb",
  storageBucket: "birkesshop-54edb.appspot.com",
  messagingSenderId: "201086319446",
  appId: "1:201086319446:web:6c6675495969445c9334f7",
  measurementId: "G-MSNEP13MMJ",
});

const db = firebase.firestore();
const storageRef = firebase.storage();
const autenticacion = firebase.auth();

if(!firebase.messaging.isSupported()){
  console.log('Not supported messaging')
}else{
  const messaging = firebase.messaging();
 console.log('Is supported messaging')
  messaging
  .getToken({
    vapidKey:
    "BKrptBiTInFyBGFbpVkHKQS8cf3tVHTnDNkD5isSjnJSidPcRlZ8GlB6ZqOCFYFgTMdk7YGDVnUDBugzf_ZfYoc",
  })
  .then((currentToken) => {
    if (currentToken) {
      db.collection("tokens")
      .doc(currentToken)
      .set({
        token: currentToken,
      })
      .then(() => {
      })
      .catch((err) => {
      });
    } else {
    }
  })
  .catch((err) => {
    console.log("Error al obtener el token: ", err);
  });
  
  messaging.onMessage((payload) => {
    toast.dark(`${payload.data.description}`, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  });
}

export { storageRef, db, autenticacion, firebase };
