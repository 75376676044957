import React, { useEffect, useState } from "react";
import { AiOutlineShoppingCart, AiOutlineClose } from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";
import "../estilos/header_styles.css";
import { Link } from "react-router-dom";
import { autenticacion, db } from "../helpers/firebase";
import { InputFormPassword, InputFormEmail } from "./inputForm";
import { toast } from "react-toastify";
import logo from "../logo/prueba_mejorada_8.png";
import { UserLanguagePrefer } from "../App";

const ButtonArtikelDetails = import("./button");
const Button = React.lazy(() => ButtonArtikelDetails);
const MenuComponentHeader = import("../paginas/menu");
const MenuComponent = React.lazy(() => MenuComponentHeader);
const ModalEdit = import("./modal_login");
const Modal = React.lazy(() => ModalEdit);

toast.configure();

function Header() {
  const [data, setData] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [valueEmail, setValueEmail] = useState({
    email: "",
  });
  const [valuePassword, setValuePassword] = useState({
    password: "",
  });

  useEffect(() => {
    autenticacion.onAuthStateChanged((user) => {
      if (user) {
        setUserEmail(user.email);
        db.collection("userBestellungen")
          .doc("userBestellt")
          .collection(user.uid)
          .onSnapshot((snapshot) => {
            setData(
              snapshot.docs.map((doc) => {
                return {
                  titulo: doc.data().titulo,
                  precio: doc.data().precio,
                  descripcion: doc.data().descripcion,
                  propiedades: doc.data().propiedades,
                  cantidad: doc.data().cantidad,
                  uso: doc.data().uso,
                  id: doc.id,
                  image: doc.data().image,
                };
              })
            );
          });
      }
    });
  }, []);

  const modalOpen = () => {
    if (userEmail === null) {
      setModalIsOpen(true);
    }
  };

  const onCloseModal = () => {
    setModalIsOpen(false);
  };

  const onChangeEmail = (ev) => {
    setValueEmail({
      email: ev.target.value,
    });
     data.map((item) => {
      return item.titulo;
    });
 
  };

  const onChangePassword = (ev) => {
    setValuePassword({
      password: ev.target.value,
    });
  };

  const onLogin = () => {
    autenticacion
      .signInWithEmailAndPassword(valueEmail.email, valuePassword.password)
      .then(() => {
        console.log(valueEmail.email);
        db.collection("users")
          .where("user_email", "==", valueEmail.email)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              data.map((item) => {
                return db
                  .collection("userBestellungen")
                  .doc("userBestellt")
                  .collection(doc.id)
                  .doc(item.id)
                  .set({
                    titulo: item.titulo,
                    precio: item.precio,
                    descripcion: item.descripcion,
                    propiedades: item.propiedades,
                    cantidad: item.cantidad,
                    uso: item.uso,
                    id: item.id,
                    image: item.image,
                  })
                  .then(() => {
                    window.location.reload();
                    console.log("se agregaron tus articulos al carrito");
                  });
              });
            });
          });

        setModalIsOpen(false);
        toast.success("Te registraste correctamente", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error("Email o contraseña incorrecta", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log("email o contraseña incorrectos", err.message);
      });
  };

  return (
    <React.Suspense fallback={<span>Loading...</span>}>
      <UserLanguagePrefer.Consumer>
      {
        value => 
      <div className="header_container">
        <div className="header_logo_container">
        <MenuComponent />
          <img src={logo} alt="logo" className="header_logo"/>
        </div>
        <Link to="/kaufwagen" onClick={modalOpen} className="header_link">
          {data.length === 0 ? (
            <AiOutlineShoppingCart className="header_shopping_car" />
            ) : (
              <FaShoppingCart className="header_shopping_car" />
              )}
        </Link>
        <React.Suspense fallback={null}>
          <Modal
            classNameModal="login_modal_container"
            isModalOpen={modalIsOpen}
            onCloseModal={onCloseModal}
            >
            <AiOutlineClose
              onClick={onCloseModal}
              className="login_modal_close"
              />
            <form className="login_modal_form">
             <h1 className="login_modal_title">Login</h1>
              <InputFormEmail
                inputFormEmail_container='login_modal_input_email_container'
                email_className="login_modal_input_email"
                placeholderInputEmail='Email'
                onChangeValue={onChangeEmail}
                name="email"
                valueForm={valueEmail.email}
                />
              <InputFormPassword
                inputPassword_container='login_modal_input_password_container'
                password_className="login_modal_input_password"
                placeholderInputPassword={
                  value === 'ES'
                  ? 'Contraseña'
                  : 'Password'
                }
                onChangeValue={onChangePassword}
                name="password"
                valueForm={valuePassword.password}
                />

              <Link
                rel="preload"
                to="/user_password_reset"
                className="login_modal_password_link"
                onClick={onCloseModal}
                >{
                  value === 'ES'
                  ? 'Has olvidado tu contraseña?'
                  : 'Password vergessen?'
                }
              </Link>
              <Button
                valueButton={
                  value === 'ES'
                  ? 'Entrar'
                  : 'Login'
                }
                button="login_modal_button_entrar"
                onClickButton={onLogin}
                />
            <h5 className="login_modal_text">
             {
               value === 'ES'
               ? 'Aun no tienes cuenta? Crea una'
               : 'Hast du noch keinen Account? Dann click'
              }
              <Link
                rel="preload"
                to="/create_account"
                className="login_modal_link"
                onClick={onCloseModal}
                >
                  {
                    value === 'ES'
                    ? 'Aquí'
                    : 'Hier'
                  }
              </Link>
            </h5>
            <div className="login_modal_anonim_container">
              <h5 className="login_modal_text">{
              value === 'ES'
             ? 'O seguir como anónimo'
             : 'Oder weiter als anonym'
            }</h5>
              <Button
                valueButton={
                  value === 'ES'
                 ? "Sigue como anónimo"
                 : 'Weiter als anonym'
                }
                 button="login_modal_button_anonym"
                onClickButton={onCloseModal}
                />
             </div>
            </form>
          </Modal>
        </React.Suspense>
        </div>
}
       </UserLanguagePrefer.Consumer>
    </React.Suspense>
  );
}

export default Header;
